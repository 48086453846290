<div class="c-getknow">
    <div class="c-getknow__section-hero">
        <img src="../../../assets/getknow/bcg_o-madero.jpg">
    </div>
    <div class="c-getknow__section-who">
        <h4>Como o <b>Madero Cashback</b> funciona?</h4>
        <hr>
        <p>O Madero Cashback é um programa de fidelidade que fornece créditos retornáveis para consumo no Madero. Os créditos são acumulados durante uma ou mais visitas nos restaurantes participantes e, uma vez resgatados, podem ser utilizados como desconto sobre o valor total na sua próxima consumação. Veja como é fácil participar:</p>
        <div class="c-getknow__section-who--cards">
            <div class="c-card">
                <img src="../../../assets/getknow/passo_1-2.png">
                <h4>Cadastre-se</h4>
                <p>O cadastro é rápido e seguro!</p>
            </div>
            <div class="c-card">
                <img src="../../../assets/getknow/Passo2-2.png">
                <h4>Acumule</h4>
                <p>Consuma em qualquer um dos restaurantes participantes e informe seu CPF na nota fiscal.</p>
            </div>
            <div class="c-card">
                <img src="../../../assets/getknow/Passo3-2.png">
                <h4>Troque</h4>
                <p>No aplicativo do grupo, consulte seu saldo e resgate seus créditos para desconto em sua próxima consumação.</p>
            </div>
        </div>
    </div>
</div>
<div class="c-flex">
    <h4>Vem ser Madero Cashback, cadastre-se agora no nosso aplicativo.</h4>
    <div class="c-stores">
        <div class="c-stores__item" (click)="onRedirect('apple')">
            <img src="../../../assets/store-apple.png" class="c-cursor" alt="">
        </div>
        <div class="c-stores__item" (click)="onRedirect('google')">
            <img src="../../../assets/store-google.png" class="c-cursor" alt="">
        </div>
    </div>
</div>
<div class="c-creditcards">
    <h4>Para mudar de <b>categoria é simples.</b></h4>
    <hr>
    <div class="c-creditcards__cards">
        <div class="c-creditcards-card">
            <img src="../../../assets/getknow/Black-1.png">
            <h4>Madero Cashback Black</h4>
            <p>Clientes com consumação acima de R$ 1.900,00 nos últimos seis meses acumulam 10,0% de créditos retornáveis sobre o valor total da compra.</p>
        </div>
        <div class="c-creditcards-card">
            <img src="../../../assets/getknow/Gold.png">
            <h4>Madero Cashback Green</h4>
            <p>Clientes com consumação entre R$ 900,00 e R$1.899,99 nos últimos seis meses acumulam 5,0% de créditos retornáveis sobre o valor total da compra.</p>
        </div>
        <div class="c-creditcards-card">
            <img src="../../../assets/getknow/Blue.png">
            <h4>Madero Cashback Blue</h4>
            <p>Clientes com consumação entre R$ 300,00 e R$ 899,99, nos últimos seis meses acumulam 3,0% de créditos retornáveis sobre o valor total da compra.</p>
        </div>
        <div class="c-creditcards-card">
            <img src="../../../assets/getknow/platinum.png">
            <h4>Madero Cashback White</h4>
            <p>Categoria de cadastro para clientes com consumação de até R$ 299,99 nos últimos 6 meses. Não acumula crédito.</p>
        </div>
    </div>
</div>
<div class="c-end">
    <h4>Ainda com <b>DÚVIDAS?</b></h4>
    <hr>
    <br>
    <a class="btn btn-black" routerlink="/doubts" href="/doubts"> ACESSE NOSSA CENTRAL DE SOLUÇÕES </a>
</div>