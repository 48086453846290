<div class="c-faq">
    <div class="c-faq__who">
        <h4>Dúvidas sobre como o <b>MADERO CASHBACK</b> funciona?</h4>
        <hr>
        <p>Tire suas dúvidas através das perguntas mais frequentes que chegam até nossa central de soluções.</p>
    </div>
</div>

<div class="c-accordion">
    <mat-accordion>
        @for (item of accordionList; track $index) {
            <mat-expansion-panel class="c-panel">
                <mat-expansion-panel-header class="c-panel__header">
                    <mat-panel-title class="c-panel__header--title"> 
                        <mat-icon class="c-panel__header--icon">{{ item.icon }}</mat-icon>
                        {{ item.title }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{ item.description }}</p>
            </mat-expansion-panel>
        }
    </mat-accordion>
</div>
