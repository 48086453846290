import { Component } from '@angular/core';

@Component({
  selector: 'app-getknow',
  standalone: true,
  imports: [],
  templateUrl: './getknow.component.html',
  styleUrl: './getknow.component.scss'
})
export class GetknowComponent {
  private readonly urlGoogle = 'https://play.google.com/store/apps/details?id=br.com.alphacode.madero&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  private readonly urlApple = 'https://apps.apple.com/br/app/grupo-madero-app/id1579688707?l=en';

  onRedirect(nameLink: string): void{
    switch (nameLink) {
      case 'google':
        window.open(this.urlGoogle, '_blank')
        break;
      case 'apple':
        window.open(this.urlApple, '_blank')
        break;
    
      default:
        break;
    }
  }
}
