import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  private readonly urPrivacy = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Politica_de_Privacidade_Madero_Cashback.pdf';
  private readonly urTerms = 'https://madero.blob.core.windows.net/fidelidade/Documentos/Regulamento_Madero_Cashback.pdf';

  onRedirect(nameLink: string): void{
    switch (nameLink) {
      case 'terms':
        window.open(this.urTerms, '_blank')
        break;
      case 'privacy':
        window.open(this.urPrivacy, '_blank')
        break;
    
      default:
        break;
    }
  }
}
