import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RegisterComponent } from './pages/register/register.component';
import { GetknowComponent } from './pages/getknow/getknow.component';
import { DoubtsComponent } from './pages/doubts/doubts.component';
import { LoginComponent } from './pages/login/login.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        title: 'Home | Madero Cashback',
        component: HomeComponent,
    },
    {
        path: 'register',
        title: 'Cadastre-se | Madero Cashback',
        component: RegisterComponent,
    },
    {
        path: 'getknow',
        title: 'Conheça | Madero Cashback',
        component: GetknowComponent,
    },
    {
        path: 'doubts',
        title: 'Dúvidas Frequentes | Madero Cashback',
        component: DoubtsComponent,
    },
    {
        path: 'login',
        title: 'Entrar | Madero Cashback',
        component: LoginComponent,
    },
    {
        path: 'not-found',
        title: 'Página não encontrada | Madero Cashback',
        component: NotFoundComponent,
    },
    {
        path: '**',
        redirectTo: 'not-found',
        pathMatch: 'full',
    }
];
