<div class="c-hero">
    <div class="c-hero__content">
        <h1>Seja bem-vindo!</h1>
        <p>Você está prestes a se tornar <br> membro do Madero Cashback</p>
    </div>
    <div class="c-hero__content">
        <img src="../../../assets/card-home.png" class="floating" alt="">
    </div>
</div>
<div class="c-footer">
    <div class="c-footer__item">
        <h1>Preencha suas informações para continuar</h1>
        <form class="c-form"
            appearance="outline"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">

            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>Nome</mat-label>
                <input
                    matInput
                    placeholder="Nome..."
                    formControlName="name"
                    placeholder="Insira seu Nome"
                />    
                @if (form.get('name')?.hasError('required')) {
                    <mat-error>Insira seu Nome</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>E-mail</mat-label>
                <input
                    matInput
                    placeholder="E-mail..."
                    formControlName="mail"
                    placeholder="Insira seu E-mail"
                />    
                @if (form.get('mail')?.hasError('required')) {
                    <mat-error>Insira seu E-mail</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>CPF</mat-label>
                <input
                    matInput
                    placeholder="CPF..."
                    formControlName="cpf"
                    placeholder="Insira seu CPF"
                    mask="000.000.000-00"
                />    
                @if (form.get('cpf')?.hasError('required')) {
                    <mat-error>Insira seu CPF</mat-error>
                }
            </mat-form-field>
            <mat-form-field appearance="outline" class="c-form__field">
                <mat-label>Telefone</mat-label>
                <input
                    matInput
                    placeholder="Telefone..."
                    formControlName="phone"
                    placeholder="Insira seu Telefone"
                    mask="(00) 00000-0000"
                />    
                @if (form.get('phone')?.hasError('required')) {
                    <mat-error>Insira seu Telefone</mat-error>
                }
            </mat-form-field>
            <mat-checkbox color="primary" class="example-margin" formControlName="terms">Aceito os termos do programa de fidelidade Madero Cashback</mat-checkbox>
            <button
                mat-flat-button
                class="c-btn c-btn--primary"
                type="submit"
                [disabled]="!form.valid"
            >
                @if (loading) {
                <div class="c-btn-spinner">
                    <mat-spinner class="c-btn--spinner"></mat-spinner>
                    <span>Carregando...</span>
                </div>
                } @else {
                    <span>Cadastrar</span>
                }
            </button>
        </form>
    </div>
</div>
<div class="c-footer__item dark">
    <div class="c-flex">
        <h4>Baixe o app e acompanhe seu saldo na sua carteira virtual</h4>
        <div class="c-stores">
            <div class="c-stores__item" (click)="onRedirect('apple')">
                <img src="../../../assets/store-apple.png" class="c-cursor" alt="">
            </div>
            <div class="c-stores__item" (click)="onRedirect('google')">
                <img src="../../../assets/store-google.png" class="c-cursor" alt="">
            </div>
        </div>
    </div>
</div>