import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { NavbarComponent } from "../../shared/navbar/navbar.component";

@Component({
  selector: 'app-home',
  standalone: true, 
  providers: [
    provideNgxMask()
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaskDirective,
    MatIconModule,
    NavbarComponent,
    NavbarComponent
],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent{
  private readonly urlGoogle = 'https://play.google.com/store/apps/details?id=br.com.alphacode.madero&hl=pt_BR&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  private readonly urlApple = 'https://apps.apple.com/br/app/grupo-madero-app/id1579688707?l=en';

  onRedirect(nameLink: string): void{
    switch (nameLink) {
      case 'google':
        window.open(this.urlGoogle, '_blank')
        break;
      case 'apple':
        window.open(this.urlApple, '_blank')
        break;
    
      default:
        break;
    }
  }
}
