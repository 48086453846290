import { Component, HostBinding, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  menuOpen = false;
  router = inject(Router);
  activeRoute: string = 'home';

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  @HostBinding('class.menu-open') get isMenuOpen() {
    return this.menuOpen;
  }

  navigate(route: string): void {
    this.activeRoute = route;
    console.log(route);
    
    this.router.navigate([route]);
    this.toggleMenu();
  }
}
