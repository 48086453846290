import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import  {MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-doubts',
  standalone: true,
  imports: [
    MatIconModule,
    MatExpansionModule
  ],
  templateUrl: './doubts.component.html',
  styleUrl: './doubts.component.scss'
})
export class DoubtsComponent {
  accordionList = [
    {
      icon: 'person_add',
      title: 'Quanto custa para participar?',
      description: 'O cadastro no Madero Cashback é totalmente gratuito. Com um cadastro simplificado você já pode começar a acumular Madero Cashback.'
    },
    {
      icon: 'place',
      title: 'Onde posso usar meu saldo?',
      description: 'Você pode utilizar seu saldo em todos os restaurantes Madero.'
    },
    {
      icon: 'attach_money',
      title: 'Como faço para acumular?',
      description: 'Basta frequentar os restaurantes Madero e sempre informar seu CPF no ato do pagamento para que seu consumo seja contabilizado.'
    },
    {
      icon: 'check',
      title: 'Como faço para utilizar o meu saldo?',
      description: 'Acesse sua conta no aplicativo, entre na sessão Cashback e clique no botão RESGATAR SALDO para utilizar no restaurante. Esse saldo ficará disponível para uso até o final do dia.'
    },
    {
      icon: 'show_chart',
      title: 'Como avançar de categoria?',
      description: 'Ao atingir o valor mínimo exigido para entrar na próxima categoria, você avança de categoria automaticamente.'
    },
    {
      icon: 'credit_card',
      title: 'Em quais restaurantes posso usar?',
      description: 'Todos os restaurantes com marca Madero.'
    }
  ]
}
