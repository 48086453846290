<div class="c-hero">
    <img src="../../../assets/titulo-banner.png" alt="" srcset="">
    <p>Este é o programa fidelidade do Madero. Ao frequentar os restaurantes Madero você recebe parte do valor consumido em seu saldo <b>Madero Cashback</b> e pode utilizá-lo em suas próximas visitas. </p>
</div>
<div class="c-flex">
    <h4>Vem ser Madero Cashback, cadastre-se agora no nosso aplicativo.</h4>
    <div class="c-stores">
        <div class="c-stores__item" (click)="onRedirect('apple')">
            <img src="../../../assets/store-apple.png" class="c-cursor" alt="">
        </div>
        <div class="c-stores__item" (click)="onRedirect('google')">
            <img src="../../../assets/store-google.png" class="c-cursor" alt="">
        </div>
    </div>
</div>