<div class="c-navbar">
    <div class="c-navbar__section">
        <img src="../../../assets/logo-mcb.png" alt="">
    </div>
    <div class="c-navbar__section">
        <button mat-mini-fab color="primary" class="btn-nav" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <ul class="c-navbar__section--list" [class.open]="menuOpen">
            <li [class.active]="activeRoute === 'home'" (click)="navigate('home')">
              <span>Home</span>
            </li>
            <li [class.active]="activeRoute === 'getknow'" (click)="navigate('getknow')">
              <span>Conheça</span>
            </li>
            <li [class.active]="activeRoute === 'register'" (click)="navigate('register')">
              <span>Cadastre-se</span>
            </li>
            <li [class.active]="activeRoute === 'doubts'" (click)="navigate('doubts')">
              <span>Dúvidas Frequentes</span>
            </li>
            <li [class.active]="activeRoute === 'login'" (click)="navigate('login')">
              <span>Entrar</span>
            </li>
        </ul>          
    </div>
</div>
